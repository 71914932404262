(function () {
  const canvas = document.getElementById('matrix');
  const context = canvas.getContext('2d');
  const parent = canvas.parentNode;
  // canvas isn't responsive, so we have to resize with JS
  const canvasWidth = (canvas.width = parent.offsetWidth);
  const canvasHeight = (canvas.height = parent.offsetWidth * 0.56);
  // each time we paint, the slight opacity will cause
  // previous frames to gradually fade away
  const backgroundColor = 'rgba(0,0,0,0.02)';
  const textColor = '#8BC34A';
  const textFlashColor = '#FFFFFF';
  context.font = 'normal 18px webdings';

  const glyphs = ['⑅', '␥', '⑀', '⑃', '⑄', '⏣', '⏛', '⑆'];

  // initialize the data to represent the columns of characters
  const columns = [];
  for (let i = 0; i <= 30; i++) {
    columns.push({
      // glyph: Math.floor 10*Math.random()
      // glyph: glyphs[Math.floor glyphs.length*Math.random()]
      glyph: 'A',
      x: canvasWidth * Math.random(),
      y: -500 * Math.random(),
      frame: 0,
    });
  }

  const updateData = () =>
    (() => {
      const result = [];
      for (let column of Array.from(columns)) {
        column.frame++;
        // new character every 5 frames
        if (column.frame > 5) {
          // column.glyph = Math.floor 10*Math.random() # random number
          column.glyph = glyphs[Math.floor(glyphs.length * Math.random())];
          column.y += 20;
          column.frame = 0;
        }
        if (column.y > canvasHeight) {
          result.push((column.y = -50 * Math.random()));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();

  const paintCanvas = function () {
    // paint the background
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvasWidth, canvasHeight);
    // paint the characters
    return (() => {
      const result = [];
      for (let column of Array.from(columns)) {
        if (column.frame < 2) {
          context.fillStyle = textFlashColor;
        } else {
          context.fillStyle = textColor;
        }
        result.push(context.fillText(column.glyph, column.x, column.y));
      }
      return result;
    })();
  };

  const processFrame = function () {
    updateData();
    paintCanvas();
    // run the code only when the display can use it
    return requestAnimationFrame(processFrame);
  };

  // start the recursive loop
  requestAnimationFrame(processFrame);
})();
